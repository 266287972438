import React from 'react'
import { CssBaseline } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import favicon from '../images/favicon.ico'
import { withStyles } from '@material-ui/styles'
import { styles } from './Form/styles'

const NotAllowedComponent = ({ classes }) => {
  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid
            spacing={10}
            alignItems="center"
            justifyContent="center"
            container
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Card className={classes.paper}>
                <CardContent className={classes.cardContent}>
                  <img
                    src={favicon}
                    alt="Red Hat Logo"
                    className={classes.topRight}
                  />
                  <Typography variant="h5" component="h1">
                    Access not allowed.
                    <br />
                    <br />
                    Please email{' '}
                    <a href="mailto:pitchbuilder@redhat.com">
                      pitchbuilder@redhat.com
                    </a>{' '}
                    to request access.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export const NotAllowed = withStyles(styles)(NotAllowedComponent)
