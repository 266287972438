var CONFIG_STG = {
  // baseURL: "https://pitchbuilder.preprod.be.redhat.com",
  baseURL: 'https://pitchbuilder.preprod.redhat.com/api',
  feURL: 'https://pitchbuilder.preprod.redhat.com',
}

var CONFIG_PRD = {
  // baseURL: 'https://pitchbuilder.be.redhat.com',
  baseURL: 'https://pitchbuilder.redhat.com/api',
  feURL: 'https://pitchbuilder.redhat.com',
}

var IDENTITY_CONFIG_STG = {
  authority: 'https://auth.stage.redhat.com/auth/realms/EmployeeIDP',
  client_id: 'pitchbuilder',
  redirect_uri: 'https://pitchbuilder.preprod.redhat.com/',
  automaticSilentRenew: false,
  loadUserInfo: true,
  post_logout_redirect_uri: 'https://pitchbuilder.preprod.redhat.com/',
  response_type: 'code',
  scope: 'openid',
}
var IDENTITY_CONFIG_PRD = {
  authority: 'https://auth.redhat.com/auth/realms/EmployeeIDP', //(string): The URL of the OIDC provider.
  client_id: 'pitchbuilder', //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: 'https://pitchbuilder.redhat.com/', //The URI of your client application to receive a response from the OIDC provider.
  automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  post_logout_redirect_uri: 'https://pitchbuilder.redhat.com/', // (string): The OIDC post-logout redirect URI. //is there a way to specific the audience when making the jwt
  response_type: 'code', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  scope: 'openid',
}

var METADATA_OIDC_STG = {
  issuer: 'https://auth.stage.redhat.com/auth/realms/EmployeeIDP',
  jwks_uri:
    'https://auth.stage.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/certs',
  authorization_endpoint:
    'https://auth.stage.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/auth',
  end_session_endpoint:
    'https://auth.stage.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/logout',
  userinfo_endpoint:
    'https://auth.stage.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/userinfo',
  token_endpoint: 'https://pitchbuilder.preprod.redhat.com/api/token',
}

var METADATA_OIDC_PRD = {
  issuer: 'https://auth.redhat.com/auth/realms/EmployeeIDP',
  jwks_uri:
    'https://auth.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/certs',
  authorization_endpoint:
    'https://auth.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/auth',
  end_session_endpoint:
    'https://auth.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/logout',
  userinfo_endpoint:
    'https://auth.redhat.com/auth/realms/EmployeeIDP/protocol/openid-connect/userinfo',
  token_endpoint: 'https://pitchbuilder.redhat.com/api/token',
}

const hostname = window && window.location && window.location.hostname

export const IS_PROD = hostname.indexOf('pitchbuilder.redhat.com') > -1
export const ENV_NAME = IS_PROD ? 'production' : 'preprod'

export const CONFIG = IS_PROD ? CONFIG_PRD : CONFIG_STG
export const METADATA_OIDC = IS_PROD ? METADATA_OIDC_PRD : METADATA_OIDC_STG
export const IDENTITY_CONFIG = IS_PROD
  ? IDENTITY_CONFIG_PRD
  : IDENTITY_CONFIG_STG
