import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'

import { AuthConsumer } from '../AuthProvider'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'black',
    height: '100vh',
  },
  root: {
    flexGrow: 1,
    padding: '50px',
    minHeight: '200px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    maxWidth: '136px',
  },
  toolbar: {
    backgroundColor: '#151515',
    height: '72px',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    color: 'black',
  },
  card: {
    minHeight: '200px',
  },
  login: {
    backgroundColor: 'red',
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    width: '200px',
  },
  btnGroup: {
    padding: '20px',
  },
}))

export default function Error() {
  const classes = useStyles()
  return (
    <AuthConsumer>
      {({ logout }) => {
        return (
          <div className={classes.container}>
            <AppBar position="static" className={classes.toolbar}>
              <Toolbar className={classes.toolbar}>
                <img
                  alt="Red Hat Logo"
                  src="https://sso.stage.redhat.com/auth/resources/rfwmc/login/rhd-theme/img/RHLogo_white.svg"
                  className={classes.logo}
                />
              </Toolbar>
            </AppBar>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Please login using your @redhat email.
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.btnGroup}>
                      <Button
                        size="large"
                        className={classes.login}
                        variant="contained"
                        onClick={() => logout()}
                      >
                        Log in
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        )
      }}
    </AuthConsumer>
  )
}
