import React, { useContext, useRef } from 'react'
import AuthService from './AuthService'

const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  isValidEmail: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
})

export const useAuthContext = () => useContext(AuthContext)

export const AuthConsumer = AuthContext.Consumer

export const AuthProvider = ({ children }) => {
  const authServiceRef = useRef()
  if (!authServiceRef.current) {
    authServiceRef.current = new AuthService()
  }

  return (
    <AuthContext.Provider value={authServiceRef.current}>
      {children}
    </AuthContext.Provider>
  )
}
