import React, { useRef, useEffect } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import { withRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import CardContent from '@material-ui/core/CardContent'
import Link from '@material-ui/core/Link'
import { Box, Button, CircularProgress, Fab } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { green, red } from '@material-ui/core/colors'
import clsx from 'clsx'
import { useProgress } from './ProgressContext'
import { debugViewStyles } from './Form/styles'
import { pitchBuilderUrlAxios } from './BaseApiUrl'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['A500'],
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    marginTop: 20,
    padding: 20,
    paddingBottom: 200,
  },
  grid: {
    width: 1000,
  },
  paper: {
    overflowX: 'scroll',
    padding: theme.spacing(3),
    textAlign: 'left',
    marginTop: 20,
    color: theme.palette.text.secondary,
  },
  checkmarkSuccess: {
    backgroundColor: green[500],
  },
  checkmarkFailed: {
    backgroundColor: red[500],
  },
  checkmark: {
    position: 'relative',
    left: '6px',
  },
  progress: {
    position: 'absolute',
  },
  progressDone: {
    visibility: 'hidden',
  },
  debugView: debugViewStyles,
})

const makePresentationUrl = (id) =>
  `https://docs.google.com/presentation/d/${id}`

const getProgressMessage = (isLoading) => {
  if (isLoading) {
    return 'Preparing Presentation ...'
  }
  return 'Presentation Ready!'
}

const NO_RESULTS_MSG =
  'No results found. Please wait 15 minutes and check your email, then try again.'

const Final = ({ classes }) => {
  const progress = useProgress()
  const timerRef = useRef(Date.now())

  useEffect(() => {
    // User refreshed or opened link in new tab, we will manually set loading
    if (
      progress.requestId &&
      !progress.isLoading &&
      !progress.error &&
      !progress.getDebugResults() &&
      !progress.presentationId
    ) {
      progress.setLoading(true)
    }
  }, [])

  useEffect(() => {
    if (
      progress.getDebugResults() ||
      progress.presentationId ||
      progress.error
    ) {
      return
    }
    const interval = setInterval(() => {
      // Wait 7 minutes then show error
      if (Date.now() - timerRef.current > 7 * 60 * 1000) {
        progress.setError(NO_RESULTS_MSG)
      }
      pitchBuilderUrlAxios
        .post('/api/v1/get_results', {
          request_id: progress.requestId,
        })
        .then(({ data }) => {
          if (data.status === 'processing') {
            console.log('still processing...')
            return
          }
          if (progress.isDebug && data.data) {
            progress.setDebugResults(data.data)
          } else {
            progress.setPresentationId(data.slide_id)
          }
          progress.setLoading(false)
        })
        .catch((err) => {
          if (!err.response) {
            console.error('response poll error', err)
            return
          }
          if (err.response.status === 401) {
            // Wait for token to update
            return
          }
          if (err.response.status === 404) {
            console.log('request not found')
          } else {
            console.error('error getting results', err.response.data)
          }
          progress.setError(NO_RESULTS_MSG)
        })
    }, 10 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [
    progress.isDebug,
    progress.requestId,
    progress.presentationId,
    !!progress.getDebugResults(),
  ])

  const openPresentation = () => {
    if (progress.isDebug) {
      alert('In debug mode, no presentation is created.')
      return
    }
    if (!progress.presentationId) {
      return
    }
    window.open(makePresentationUrl(progress.presentationId), '_blank')
  }

  const printDebugResults = () => {
    return (
      <Table aria-label="debug-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Parameter</b>
            </TableCell>
            <TableCell>
              <b>Replacement Value</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(progress.getDebugResults()).map((key) => (
            <TableRow>
              <TableCell>{key}</TableCell>
              <TableCell>
                <pre>
                  {JSON.stringify(progress.getDebugResults()[key], null, '\t')}
                </pre>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  return (
    <React.Fragment>
      {progress.isDebug ? (
        <div className={classes.debugView}>DEBUG MODE</div>
      ) : null}
      <CssBaseline />
      <div className={classes.root}>
        <Grid container justifyContent="center">
          <Grid
            spacing={10}
            alignItems="center"
            justifyContent="center"
            container
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Card className={classes.paper}>
                <CardContent>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h4" component="h1">
                      Thank you for your submission. You will receive an email
                      with your slide deck. Closing this tab will not stop the
                      process from finishing.
                    </Typography>
                    <Box marginY={3} display="flex" alignItems="center">
                      <CircularProgress
                        size={68}
                        className={clsx(classes.progress, {
                          [classes.progressDone]:
                            !progress.isLoading || progress.error,
                        })}
                      />
                      <Fab
                        disabled={!progress.presentationId}
                        onClick={openPresentation}
                        className={clsx(classes.checkmark, {
                          [classes.checkmarkSuccess]: progress.presentationId,
                          [classes.checkmarkFailed]: progress.error,
                        })}
                      >
                        {progress.error ? <CloseIcon /> : <CheckIcon />}
                      </Fab>
                      <Box marginX={3}>
                        {progress.error ? (
                          <Typography>{progress.error}</Typography>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={!progress.presentationId}
                            onClick={openPresentation}
                          >
                            {getProgressMessage(progress.isLoading)}
                          </Button>
                        )}
                      </Box>
                    </Box>
                    <Link href="/#/main" underline="always">
                      Submit another request
                    </Link>
                    {progress.getDebugResults() && progress.isDebug
                      ? printDebugResults()
                      : null}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withStyles(styles)(Final))
