export const formValidators = [
  {
    name: 'otherRole',
    message: 'This is a required question.',
    hasError: (values) => values.role === 'Other' && !values.otherRole,
  },
  {
    name: 'appendix',
    message:
      'Please limit your appendix section to FIVE topics. If you would like to preview the content before your selection, go to the PitchBuilder source page: https://source.redhat.com/groups/public/pitchbuilder (view "quick links" options on left "preview appendix options")',
    hasError: (values) => values.appendix.length > 5,
  },
  {
    name: 'accounts',
    message: 'This is a required question.',
    hasError: (values) => !values.accounts,
  },
  {
    name: 'language',
    message: 'This is a required question.',
    hasError: (values) => !values.language,
  },
  {
    name: 'timeframe_days',
    message: 'Timeframe must be a numeric value.',
    hasError: (values) => Number.isNaN(values.timeframe_days),
  },
]

export const validateForm = (validators, values) =>
  validators.reduce(
    (result, validator) => {
      if (validator.hasError(values)) {
        return {
          hasError: true,
          errors: {
            ...result.errors,
            [validator.name]: validator.message,
          },
        }
      }
      return result
    },
    { hasError: false, errors: {} }
  )
