import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { ENV_NAME } from './Config'

Sentry.init({
  dsn: 'https://111718d83215476bb4eb3667ca1a320e@o490301.ingest.sentry.io/4505036444532736',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: ENV_NAME,
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
