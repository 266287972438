import { UserManager, WebStorageStateStore, Log } from 'oidc-client'
import { CONFIG, IDENTITY_CONFIG, METADATA_OIDC, IS_PROD } from './Config'

export default class AuthService {
  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    })
    Log.logger = console
    Log.level = IS_PROD ? Log.INFO : Log.DEBUG

    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message)
    })

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired')
      this.signinSilent()
    })
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback()
      .then(() => {
        console.log('signedin' + this.isValidEmail())
        if (this.isValidEmail()) {
          window.location.replace(CONFIG.feURL + '/#/main')
        } else {
          window.location.replace(CONFIG.feURL + '/#/error')
        }
      })
      .catch((e) => {
        if (e.message === 'No state in response') {
          console.error('Sign in redirect error', { e })
        }
        this.UserManager.signinRedirect({})
      })
  }

  getUser = async () => {
    const user = await this.UserManager.getUser()
    if (!user) {
      return await this.UserManager.signinRedirectCallback()
    }
    return user
  }

  parseJwt = (token) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname)
    this.UserManager.signinRedirect({})
  }

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          IDENTITY_CONFIG.authority +
          ':' +
          IDENTITY_CONFIG.client_id
      )
    )
    return !!oidcStorage && !!oidcStorage.access_token
  }

  isValidEmail = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          IDENTITY_CONFIG.authority +
          ':' +
          IDENTITY_CONFIG.client_id
      )
    )
    console.log(JSON.stringify(oidcStorage))
    return (
      oidcStorage &&
      ((oidcStorage.email && oidcStorage.email.indexOf('redhat.com') >= 0) ||
        (oidcStorage.profile &&
          oidcStorage.profile.email &&
          oidcStorage.profile.email.indexOf('redhat.com') >= 0))
    )
    //return (oidcStorage && oidcStorage.profile && oidcStorage.profile.email.indexOf('gmail.com')>=0)
  }

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('signed in')
      })
      .catch((err) => {
        console.log('silent sign in error', err)
        this.logout()
      })
  }
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
  }

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest()
  }

  logout = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          IDENTITY_CONFIG.authority +
          ':' +
          IDENTITY_CONFIG.client_id
      )
    )
    //console.log(JSON.stringify(oidcStorage));
    this.UserManager.signoutRedirect({
      id_token_hint: oidcStorage.id_token,
    }).then(() => {
      //window.location.replace("http://localhost:3000");
    })
    this.UserManager.clearStaleState()
  }

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear()
      window.location.replace(CONFIG.feURL)
    })
    this.UserManager.clearStaleState()
  }
}
