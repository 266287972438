import React from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom'
import Form from './components/Form'
import Final from './components/Final'
import Error from './components/Error'
import ScrollToTop from './components/ScrollTop'
import LoggedIn from './components/Login'
import { PrivateRoute } from './PrivateRoute'
import { ProgressProvider } from './components/ProgressContext'

export default () => (
  <HashRouter>
    <ScrollToTop>
      <ProgressProvider>
        <Switch>
          <PrivateRoute exact path="/main" component={Form} />
          <Route path="/final" component={Final} />
          <Route path="/error" component={Error} />
          <Route exact path="/" component={LoggedIn} />
        </Switch>
      </ProgressProvider>
    </ScrollToTop>
  </HashRouter>
)
