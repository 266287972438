import React from 'react'
import { AuthConsumer } from '../AuthProvider'

export const LoggedIn = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback()
      return <span>Loading...</span>
    }}
  </AuthConsumer>
)

export default LoggedIn
