import axios from 'axios'
import { CONFIG, IDENTITY_CONFIG } from '../Config'

const TOKEN_KEY = `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`

const pitchBuilderUrlAxios = axios.create({
  baseURL: CONFIG.baseURL,
})

pitchBuilderUrlAxios.interceptors.request.use(
  (config) => {
    const token = window.sessionStorage.getItem(TOKEN_KEY)
    const { id_token } = JSON.parse(token || '{}')
    if (!id_token) {
      throw new Error('jwt not found')
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['Authorization'] = `Bearer ${id_token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { pitchBuilderUrlAxios }
