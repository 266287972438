import React, { createContext, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

const ProgressContext = createContext({
  presentationId: null,
  requestId: null,
  setPresentationId: (_id) => {},
  error: null,
  setError: (_err) => {},
  isLoading: false,
  setLoading: (loading) => {},
  hasNoData: true,
  isDebug: false,
  getDebugResults: () => null,
  setDebugResults: (_results) => {},
})

export const useProgress = () => useContext(ProgressContext)

export const ProgressProvider = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const searchParams = new URLSearchParams(location.search)

  const isDebug = typeof searchParams.get('debug') === 'string'
  const error = searchParams.get('error')
  const presentationId = searchParams.get('id')
  const debugResultsId = searchParams.get('resultsId')
  const requestId = searchParams.get('requestId')
  const debugResultKey = `debug_results:${debugResultsId}`

  const getDebugResults = () => {
    try {
      return JSON.parse(window.sessionStorage.getItem(debugResultKey))
    } catch {
      return null
    }
  }

  const updateUrl = (key, value) => {
    const urlParams = new URLSearchParams()
    if (isDebug) urlParams.set('debug', '')
    if (requestId) urlParams.set('requestId', requestId)
    if (presentationId) urlParams.set('id', presentationId)
    if (debugResultsId) urlParams.set('resultsId', debugResultsId)
    if (error) urlParams.set('error', error)
    urlParams.set(key, value)
    history.replace(`/final/?${urlParams.toString()}`)
  }

  const setDebugResults = (results) => {
    const id = uuid()
    window.sessionStorage.setItem(
      `debug_results:${id}`,
      JSON.stringify(results)
    )
    updateUrl('resultsId', id)
  }

  const setPresentationId = (id) => updateUrl('id', id)

  const setError = (error) => updateUrl('error', error)

  const hasNoData =
    presentationId === null && error === null && getDebugResults() === null

  const value = {
    requestId,
    setPresentationId,
    presentationId,
    setError,
    error,
    setLoading,
    isLoading,
    hasNoData,

    // for debugging mode
    isDebug,
    getDebugResults,
    setDebugResults,
  }

  return (
    <ProgressContext.Provider value={value}>
      {children}
    </ProgressContext.Provider>
  )
}
