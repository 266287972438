import React, { useRef, useState } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import { withRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  FormHelperText,
  CircularProgress,
  Box,
} from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import { pitchBuilderUrlAxios } from '../BaseApiUrl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import favicon from '../../images/favicon.ico'
import { validateForm, formValidators } from './validateForm'
import { styles } from './styles'
import { appendixItems } from './constants'
import { toggleAppendix, toggleAppendixLabel } from './formStateUtils'
import { scrollToSection } from './scrollToSection'
import { getFirstErrorKey } from './getFirstErrorKey'
import { useProgress } from '../ProgressContext'
import { translation } from './translation'

const Main = ({ classes, history }) => {
  const [form, setForm] = useState({
    template: '',
    emails: '',
    request_userid: '',
    accounts: '',
    language: '',
    appendix: [],
    geo: '',
    role: 'Red Hat - Enterprise Sales Team',
    appendixlabels: [],
    from_date: moment().subtract(1, 'year'),
    to_date: moment(),
  })

  const [formLanguage , setFormLanguage] = useState("English")
  const [formValidation, setFormValidation] = useState({
    error: false,
    errorMessage: {},
  })
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const progress = useProgress()

  const formRefs = {
    language: useRef(null),
    accounts: useRef(null),
    appendix: useRef(null),
    otherRole: useRef(null),
    from_date: useRef(null),
    to_date: useRef(null),
  }
  const fieldNames = Object.keys(formRefs)

  const closeErrorDialog = () => setErrorDialogOpen(false)

  const getFormValidationResult = (formValues) => {
    const validationResult = validateForm(formValidators, formValues)
    if (validationResult.hasError) {
      setFormValidation({ error: true, errorMessage: validationResult.errors })
    }
    return validationResult
  }

  const updateFormAndValidate = (updated) => {
    setForm(updated)
    if (submitAttempted) {
      getFormValidationResult(updated)
    }
  }

  const handleCheckboxInputChange = ({ target: { name, value, checked } }) => {
    updateFormAndValidate({
      ...form,
      [name]: value,
      appendix: toggleAppendix(checked, value, form.appendix),
      appendixlabels: toggleAppendixLabel(checked, value, form.appendixlabels),
    })
  }

  const handleDateChange = (name, date) => {
    updateFormAndValidate({ ...form, [name]: date })
  }

  const handleChange = (event) => {
    let updatedForm = { ...form, [event.target.name]: event.target.value }

    if (event.target.name === 'language') {
      // change the template id when language
      const languageByTemplate = {
        English: '1HulZNCUzSSLo2ev36v8A9hYaRk330YY9vW7LB1tY4WQ',
        Japanese: '1t06F0BMN_6QLOPxgPi8bhMOldgGbUKsIaiHVGrc8TrU',
        Chinese: '1QR5txvjsIKBDxnXNP8LNdClsa7CJ3CloK9r1R7iRXpk',
        Korean: '1cvxBGGkH4h1LnHg_tBUXPUCjs1Zf9oyYTqQUzNzR7w8',
      }
      const template =
        languageByTemplate[event.target.value] || languageByTemplate['English']
      updatedForm = { ...updatedForm, template }
    }
    updateFormAndValidate(updatedForm)
  }

  const validateFormAndAccounts = async () => {
    try {
      const accountValidationResult = await pitchBuilderUrlAxios.post(
        '/api/v1/account',
        form
      )
      if (!accountValidationResult.data.valid) {
        setErrorDialogOpen(true)
        return false
      }
      return true
    } catch (e) {
      console.error('Form validation error', { e })
      return false
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitAttempted(true)
    if (submitting) {
      return
    }
    setSubmitting(true)

    const validationResult = getFormValidationResult(form)
    const errKey = getFirstErrorKey(validationResult, fieldNames)
    if (errKey) {
      scrollToSection(formRefs[errKey].current)
      setSubmitting(false)
      return
    }

    validateFormAndAccounts().then((isValid) => {
      if (!isValid) {
        setSubmitting(false)
        return
      }

      form.from_date = form.from_date.format('YYYY-MM-DD')
      form.to_date = form.to_date.format('YYYY-MM-DD')
      if (progress.isDebug) {
        form['debug_data_only'] = 'true'
      }
      progress.setLoading(true)
      pitchBuilderUrlAxios
        .post('/api/v1/slides', form)
        .then(({ data }) => {
          const requestId = data.request_id
          let navUrl = `/final?requestId=${requestId}`
          if (progress.isDebug) {
            navUrl += '&debug'
          }
          history.push(navUrl)
        })
        .catch((err) => {
          console.error('Unable to submit request', { err })
          progress.setError(
            'Something went wrong. Wait a few minutes then check your email before trying again.'
          )
          progress.setLoading(false)
        })
        .finally(() => {
          setSubmitting(false)
        })
    })
  }

  const { errorMessage } = formValidation

  return (
    <React.Fragment>
      {progress.isDebug ? (
        <div className={classes.debugView}>DEBUG MODE</div>
      ) : null}
      <Dialog open={submitting}>
        <DialogTitle>Finalizing Request</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" mb={3}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <CssBaseline />
      <form onSubmit={handleSubmit}>
        <div className={classes.root}>
          <Grid container className={classes.container}>
            <Grid
              spacing={10}
              alignItems="center"
              justifyContent="center"
              container
              className={classes.grid}
            >
              <Grid item xs={12}>
                <Card className={classes.header}>
                  <CardContent className={classes.cardContent}>
                    <img
                      src={favicon}
                      alt="Red Hat Logo"
                      className={classes.topRight}
                    />
                    <Typography variant="h3" component="h1">
                      {translation[formLanguage].headerSection1}<b>{translation[formLanguage].headerSection2}</b>{translation[formLanguage].headerSection3}
                      <br />
                      {translation[formLanguage].headerSection4}
                    </Typography>
                  </CardContent>
                </Card>
                {/*Added new dropdown for language JIRA DMC-7998*/}
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">
                      {translation[formLanguage].selectLanguage}
                    </Typography>

                    <FormControl
                      innerRef={formRefs['language']}
                      className={classes.formControl}
                      error={!!errorMessage.language}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        {translation[formLanguage].choose}
                      </InputLabel>
                      <Select
                        labelid="language-label"
                        id="language-select-outlined"
                        name="language"
                        label="Choose"
                        onChange={(e)=>setFormLanguage(e.target.value)}
                        value={formLanguage}
                      >
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="French">French</MenuItem>
                      </Select>
                      <FormHelperText>
                        {errorMessage.language ? errorMessage.language : null}
                      </FormHelperText>
                    </FormControl>
                  </CardContent>
                </Card>
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">
                      {translation[formLanguage].buildPitch}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {translation[formLanguage].buildPitchdesc}
                      <br />
                      <br />
                      {translation[formLanguage].helpEmail}
                    </Typography>
                  </CardContent>
                </Card>
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">
                      {translation[formLanguage].selectLanguage2}
                    </Typography>

                    <FormControl
                      innerRef={formRefs['language']}
                      className={classes.formControl}
                      error={!!errorMessage.language}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        {translation[formLanguage].choose}
                      </InputLabel>
                      <Select
                        labelid="language-label"
                        id="language-select-outlined"
                        name="language"
                        label="Choose"
                        onChange={handleChange}
                        value={form.language}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {Object.entries(translation[formLanguage].languageOptions).map(([key, value]) => (
                          <MenuItem key={key} value={key}>{value}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errorMessage.language ? errorMessage.language : null}
                      </FormHelperText>
                    </FormControl>
                  </CardContent>
                </Card>
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">
                      {translation[formLanguage].accountNumberPrompt}
                    </Typography>
                    <Typography variant="body1" component="p">
                      <br />
                      {translation[formLanguage].accountInstructions}

                      {translation[formLanguage].accountInstructions2}

                      <br />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://10ay.online.tableau.com/#/site/redhatanalytics/views/TeleSenseAccountSense/AccountOverview?:iid=1"
                      >
                        {translation[formLanguage].accountLink}

                      </a>
                      <br />
                      {translation[formLanguage].accountLinkInstructions}
                      <br/>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.google.com/document/d/1GFNQ9Gmddn3vno7rNCBJbrQHnGIAbebPi2oTQv4GVqo/edit"
                        >
                          {translation[formLanguage].accountintruclink}
                        </a>
                      <br/>
                    </Typography>

                    <FormControl
                      error={!!errorMessage.accounts}
                      innerRef={formRefs['accounts']}
                    >
                      <InputLabel htmlFor="accounts" value={form.accounts}>
                        {translation[formLanguage].yourAnswer}
                      </InputLabel>
                      <Input
                        error={!!errorMessage.accounts}
                        id="accounts"
                        name="accounts"
                        onChange={handleChange}
                      />
                      <FormHelperText>
                        {errorMessage.accounts ? errorMessage.accounts : null}
                      </FormHelperText>
                    </FormControl>
                  </CardContent>
                </Card>
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">
                      {translation[formLanguage].dateRangePrompt}
                    </Typography>
                    <Typography variant="body1" component="p">

                      {translation[formLanguage].dateRangeInstructions}
                    </Typography>
                    <div className={classes.dateRangeContainer}>
                      <DatePicker
                        disableFuture
                        label={translation[formLanguage].fromDate}
                        name="from_date"
                        value={form.from_date}
                        maxDate={form.to_date}
                        format="MMMM Do, YYYY"
                        onChange={handleDateChange.bind(null, 'from_date')}
                      />
                      {'-'}
                      <DatePicker
                        disableFuture
                        label={translation[formLanguage].toDate}
                        name="to_date"
                        value={form.to_date}
                        minDate={form.from_date}
                        format="MMMM Do, YYYY"
                        onChange={handleDateChange.bind(null, 'to_date')}
                      />
                    </div>
                    <FormHelperText>
                      {errorMessage.from_date ? errorMessage.from_date : null}
                    </FormHelperText>
                    <FormHelperText>
                      {errorMessage.to_date ? errorMessage.to_date : null}
                    </FormHelperText>
                  </CardContent>
                </Card>
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">

                      {translation[formLanguage].emailsPrompt}
                    </Typography>
                    <Typography variant="body1" component="p">

                      {translation[formLanguage].emailsInstructions}
                      <br />
                    </Typography>

                    <FormControl>
                      <InputLabel htmlFor="emails">{translation[formLanguage].yourAnswer}</InputLabel>
                      <Input
                        id="emails"
                        name="emails"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">
                      {translation[formLanguage].geoPrompt}
                    </Typography>
                    <FormControl
                        component="fieldset"
                        onChange={handleChange}
                    >
                      <RadioGroup
                        aria-label="geo"
                        name="geo"
                        value={form.geo}
                      >
                        {Object.entries(translation[formLanguage].geoOptions).map(([key, value]) => (
                          <FormControlLabel key={key} value={key} control={<Radio />} label={value} />
                        ))}

                      </RadioGroup>
                    </FormControl>
                  </CardContent>
                </Card>
                <Card className={classes.paper}>
                  <CardContent>
                    <Typography variant="h4" component="h1">
                      {translation[formLanguage].rolePrompt}
                    </Typography>

                    <FormControl
                      innerRef={formRefs['otherRole']}
                      onChange={handleChange}
                      error={!!errorMessage.otherRole}
                    >
                      <RadioGroup
                        aria-label="role"
                        name="role"
                        value={form.role}
                      >
                        {Object.entries(translation[formLanguage].roleOptions).map(([key, value]) => (
                          <FormControlLabel key={key} value={key} control={<Radio />} label={value} />
                        ))}
                      </RadioGroup>
                      <Input
                        id="otherRole"
                        name="otherRole"
                        onChange={handleChange}
                        error={!!errorMessage.otherRole}
                      />
                      <FormHelperText>
                        {errorMessage.otherRole ? errorMessage.otherRole : null}
                      </FormHelperText>
                    </FormControl>
                  </CardContent>
                </Card>
                <div className={classes.spaceTop}>
                  <Button
                    variant="contained"
                    color={progress.isDebug ? 'default' : 'primary'}
                    type="submit"
                    disabled={submitting}
                    className={classes.primary}
                  >
                    {progress.isDebug
                      ?translation[formLanguage].retrieveButton
                      : translation[formLanguage].submitButton}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </form>
      <Dialog
        open={errorDialogOpen}
        onClose={closeErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translation[formLanguage].invalidAccountAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withRouter(withStyles(styles)(Main))
