import { blue } from '@material-ui/core/colors'

export const debugViewStyles = {
  '@media print': {
    display: 'none',
  },
  position: 'fixed',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  zIndex: '999',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',
  width: '100%',
  height: '100%',
  fontWeight: 'bold',
  fontSize: '35px',
  color: 'rgba(0,0,0,0.7)',
  pointerEvents: 'none',
  background: `repeating-linear-gradient(
    45deg,
    rgba(0,0,0,0),
    rgba(0,0,0,0) 30px,
    rgba(0,100,255,0.05) 30px,
    rgba(0,100,255,0.05) 60px
  )`,
}

export const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['A500'],
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    marginTop: 20,
    padding: 20,
    paddingBottom: 200,
  },
  grid: {
    width: 1000,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    marginTop: 20,
    color: theme.palette.text.secondary,
  },
  header: {
    padding: theme.spacing(3),
    textAlign: 'center',
    marginTop: 5,
    background: blue[100],
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  primary: {
    marginRight: theme.spacing(2),
  },
  secondary: {
    background: theme.palette.secondary['100'],
    color: 'white',
  },
  spaceTop: {
    marginTop: 20,
  },
  fabProgress: {
    flex: 1,
    zIndex: 1,
  },
  container: {
    justifyContent: 'center',
  },
  topRight: {
    position: 'absolute',
    top: '0',
    right: '0',
    fontSize: '18px',
    width: '70px',
    transform: 'translate(30%, -30%)',
  },
  cardContent: {
    position: 'relative',
  },
  dateRangeContainer: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '1em',
    marginTop: '1.4em',
  },
  debugView: debugViewStyles,
})
