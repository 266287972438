import React, { Component } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { blue, indigo } from '@material-ui/core/colors'
import { AuthProvider } from './AuthProvider'
import Routes from './routes'
import './App.css'

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[900],
    },
    primary: {
      main: indigo[700],
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  },
})

class App extends Component {
  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Routes />
            </MuiPickersUtilsProvider>
          </AuthProvider>
        </ThemeProvider>
      </div>
    )
  }
}

export default App
