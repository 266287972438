export const getFirstErrorKey = (validationResult, keys) => {
  if (!validationResult.hasError) {
    return null
  }
  for (const errorKey of keys) {
    if (validationResult.errors[errorKey]) {
      return errorKey
    }
  }
}
