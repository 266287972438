import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuthContext } from './AuthProvider'
import { pitchBuilderUrlAxios } from './components/BaseApiUrl'
import { NotAllowed } from './components/NotAllowed'

const PrivateRouteWrapped = ({ Component, ...props }) => {
  const [hasAccess, setHasAccess] = useState(null)
  const authService = useAuthContext()

  useEffect(() => {
    authService.getUser().then((user) => {
      const { email } = user.profile
      pitchBuilderUrlAxios('/api/v1/check_access', {
        method: 'POST',
        data: { email },
        validateStatus: (status) => status === 200,
      })
        .then(() => setHasAccess(true))
        .catch(() => setHasAccess(false))
    })
  }, [])

  if (hasAccess === null) {
    return <span>Authenticating...</span>
  }

  if (hasAccess === false) {
    return <NotAllowed />
  }

  if (
    !!Component &&
    authService.isAuthenticated() &&
    authService.isValidEmail()
  ) {
    console.log('1a to main')
    return <Component {...props} />
  }

  console.log(
    '1b to login link' +
      authService.isAuthenticated() +
      authService.isValidEmail()
  )
  return <Redirect to="/" />
}

export const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <PrivateRouteWrapped Component={component} {...routeProps} />
      )}
    />
  )
}
