// translations.js
export const translation = {
  English: {
    headerSection1: "Discover the ",
    headerSection2: "value",
    headerSection3: " of a ",
    headerSection4: "RedHat subscription",
    selectLanguage: "Select the Language.",
    selectLanguage2:" Select the Language for PitchBuilder Lifecycle Selling Edition:",
    buildPitch: "Build a tailored pitch!",
    buildPitchdesc:"PitchBuilder is a self-service tool providing a customer-friendly, editable presentation highlighting the perceived value the customer has regarding THEIR subscriptions for enhanced discussions.",
    accountNumberPrompt: "WHAT ACCOUNT NUMBER(S) WOULD YOU LIKE THE PITCHBUILDER DECK CREATED FOR?",
    accountInstructions: "If you already know the EBS account number for your customer, enter it below. If you are entering multiple EBS account numbers, enter the accounts by using semicolons to join together multiple accounts like so: 123456;345678;567891. IMPORTANT NOTE: DO NOT USE COMMAS or SPACES. YOU MUST USE SEMICOLONS.",
    accountLink: "Telesense - Account Overview",
    accountintruclink: "Telesense - EBS Instructions",
    accountInstructions2:"If you need to look up the EBS account number for your customer, please use the following link to Telesense - Account Overview.",
    accountLinkInstructions: "If you need instructions for navigating how to locate the EBS account number within Telesense - Account Overview, please use the following link for instructions.",
    dateRangePrompt: "How long ago do you want to view activity for the selected accounts?",
    dateRangeInstructions: "This will filter activity on the selected accounts to the specified time frame for a more granular view.",
    emailsPrompt: "DO YOU WANT SOMEONE ELSE TO HAVE ACCESS TO THE PITCHBUILDER DECK?",
    emailsInstructions: "Consider your CSM, other people on your account team, your account manager. Enter their email(s) below. Make sure to use a comma-separated list of @redhat.com only addresses. Non Red Hat addresses will be ignored.",
    geoPrompt: "What is your GEO?*",
    rolePrompt: "WHAT IS YOUR ROLE?*",
    otherRoleLabel: "Other:",
    submitButton: "Submit Form",
    retrieveButton: "Retrieve Account Params",
    invalidAccountAlert: "Invalid Account number(s).",
    dialogTitle: "Alert",
    finalizingRequest: "Finalizing Request",
    debugMode: "DEBUG MODE",
    helpEmail: "If you have questions, please send to pitchbuilder@redhat.com",
    fromDate: "From Date",
    toDate: "To Date",
    yourAnswer: "Your Answer",
    choose: "Choose",
    languageOptions: {
      English: "English",
      Japanese: "Japanese",
      Chinese: "Simplified Chinese",
      Korean: "Korean",
    },
    roleOptions: {
      enterpriseSales: "Red Hat - Enterprise Sales Team",
      commercialSales: "Red Hat - Commercial Sales Team",
      tmeSales: "Red Hat - TME Sales Team",
      customerSuccess: "Red Hat - Customer Success Team",
      managedServices: "Managed Services [SSI/TSC/Concentrix]",
      other: "Other",
    },
    geoOptions: {
      APAC: "APAC",
      EMEA: "EMEA",
      LATAM: "LATAM",
      northAmericaCommercial: "North America - Commercial",
      northAmericaPublic: "North America - Public Sector",
      global: "Global",
    },
  },
  French: {
    headerSection1: "Découvrez la ",
    headerSection2: "valeur",
    headerSection3: " d'un ",
    headerSection4: "abonnement Red Hat",
    selectLanguage: "Sélectionnez la langue.",
    selectLanguage2:"Sélectionnez la langue pour l'édition PitchBuilder Lifecycle Selling :",
    buildPitch: "Créez une présentation sur mesure !",
    buildPitchdesc:"PitchBuilder est un outil en libre-service qui fournit une présentation modifiable et conviviale mettant en évidence la valeur perçue par le client concernant SES abonnements pour des discussions améliorées." ,
    accountNumberPrompt: "POUR QUEL(S) NUMÉRO(S) DE COMPTE SOUHAITEZ-VOUS CRÉER LA PRESENTATION PITCHBUILDER ?",
    accountInstructions: "Si vous connaissez déjà le numéro de compte EBS de votre client, entrez-le ci-dessous. Si vous saisissez plusieurs numéros de compte EBS, saisissez les comptes en utilisant des points-virgules pour les joindre comme ceci : 123456;345678;567891. REMARQUE IMPORTANTE : NE PAS UTILISER DE VIRGULES ni d'ESPACES. VOUS DEVEZ UTILISER DES POINTS-VIRGULES.",
    accountLink: "Telesense - Aperçu du Compte",
    accountLinkInstructions: "Si vous avez besoin d'instructions pour naviguer et localiser le numéro de compte EBS dans Telesense - Aperçu du Compte, veuillez utiliser le lien suivant pour les instructions.",
    accountintruclink: "Telesense - EBS Instructions",
    accountInstructions2:"Si vous devez rechercher le numéro de compte EBS de votre client, veuillez utiliser le lien suivant vers Telesense - Vue d'ensemble du compte.",
    dateRangePrompt: "Depuis combien de temps souhaitez-vous voir l'activité pour les comptes sélectionnés ?",
    dateRangeInstructions: "Cela filtrera l'activité des comptes sélectionnés en fonction de la période spécifiée pour une vue plus granulaire.",
    emailsPrompt: "DÉSIREZ-VOUS QUE QUELQU'UN D'AUTRE AIT ACCÈS AU DECK PITCHBUILDER ?",
    emailsInstructions: "Considérez votre CSM, d'autres personnes de votre équipe de compte, votre responsable de compte. Entrez leurs adresses e-mail ci-dessous. Assurez-vous d'utiliser une liste séparée par des virgules d'adresses uniquement @redhat.com. Les adresses non Red Hat seront ignorées.",
    geoPrompt: "Quel est votre GEO ?*",
    rolePrompt: "QUEL EST VOTRE RÔLE ?*",
    otherRoleLabel: "Autre :",
    submitButton: "Soumettre le formulaire",
    retrieveButton: "Récupérer les paramètres du compte",
    invalidAccountAlert: "Numéro(s) de compte invalide(s).",
    dialogTitle: "Alerte",
    finalizingRequest: "Finalisation de la demande",
    debugMode: "MODE DE DÉBOGAGE",
    helpEmail: "Si vous avez des questions, veuillez  les envoyer  à pitchbuilder@redhat.com",
    fromDate: "Date de début",
    toDate: "Date de fin",
    yourAnswer: "Votre réponse",
    choose: "Choisir",
    languageOptions: {
      English: "Anglais",
      Japanese: "Japonais",
      Chinese: "Chinois simplifié",
      Korean: "Korean",
    },
    roleOptions: {
      enterpriseSales: "Red Hat - Équipe de Vente Entreprise",
      commercialSales: "Red Hat - Équipe de Vente Commerciale",
      tmeSales: "Red Hat - Équipe de Vente TME",
      customerSuccess: "Red Hat - Équipe de Succès Client",
      managedServices: "Services Gérés [SSI/TSC/Concentrix]",
      other: "Autre",
    },
    geoOptions: {
      APAC: "APAC",
      EMEA: "EMEA",
      LATAM: "LATAM",
      northAmericaCommercial: "Amérique du Nord - Commercial",
      northAmericaPublic: "Amérique du Nord - Secteur Public",
      global: "Mondial",
    },
  },
};
